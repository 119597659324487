import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';
import { API_URL } from '@/config';
import storage from '@/utils/storage';

export const getCredits = async (): Promise<any> => {
  const access_token = storage.getToken();

  if (!access_token) {
    return;
  }
  try {
    const response = await axios.get(`${API_URL}/v1/credit?access_token=${access_token}`);
    return response;
  } catch (error) {
    // console.error('Error getCredit:', error);
    // throw error;
  }
};

export const useGetCredits = (config = {}) => {
  const { addNotification } = useNotificationStore();

  return useQuery<any, Error>({
    queryKey: ['credits'],
    refetchInterval: 3000,
    queryFn: () => getCredits(),
    onError: (error) => {},
    onSuccess: (data) => {
      // Handle success here if needed
    },
    ...config,
  });
};

import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';
import storage from '@/utils/storage';
import { API_URL } from '@/config';

export const getRepositoryById = async (repo_id: number): Promise<any> => {
  const access_token = storage.getToken();

  if (!access_token && !repo_id) {
    return;
  }

  try {
    const response = await axios.get(
      `${API_URL}/v2/repository/${repo_id}?access_token=${access_token}`
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const useGetRepositoryById = (repo_id: number, config = {}) => {
  const { addNotification } = useNotificationStore();

  return useQuery<any, Error>(['repository', repo_id], () => getRepositoryById(repo_id), {
    onError: (error) => {
      addNotification({
        type: 'error',
        title: 'Failed to fetch repository',
        message: error.response?.data?.data || error.message,
      });
    },
    onSuccess: (data) => {
      // Optional: handle success if needed
    },
    ...config,
  });
};

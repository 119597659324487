import { AppProvider } from '@/providers/app';
import { AppRoutes } from '@/routes';
import { ThemeProvider, BaseStyles, theme } from '@primer/react';
import deepmerge from 'deepmerge';
import './index.css';
import { AppContextProvider } from '@/context/AppContext';

const customTheme = deepmerge(theme, {
  fonts: {
    normal: 'avenir, sans-serif',
  },
});

function App() {
  return (
    <AppProvider>
      <AppContextProvider>
        <ThemeProvider colorMode="night" nightScheme="dark_dimmed" theme={customTheme}>
          <BaseStyles>
            <AppRoutes />
          </BaseStyles>
        </ThemeProvider>
      </AppContextProvider>
    </AppProvider>
  );
}

export default App;

import React from 'react';
import { ActionMenu, Box } from '@primer/react';
import { DotFillIcon } from '@primer/octicons-react';
import { CurrencyDollarSimple, Spinner } from 'phosphor-react';

type DropdownMorphedProps = {
  children: React.ReactNode;
  status: string;
  creditsLoading: boolean;
  credits: any;
  workspaceStatus: string;
};

export const DropdownMorphed: React.FC<DropdownMorphedProps> = ({
  children,
  status,
  creditsLoading,
  credits,
  workspaceStatus,
}) => {
  return (
    <ActionMenu>
      <ActionMenu.Button
        disabled={creditsLoading}
        alignContent="center"
        sx={{
          border: `1px solid #464b51`,
          borderRadius: '6px',
          fontSize: '12px',
          backgroundColor:
            'var(--bgColor-neutral-muted, var(--color-neutral-subtle, rgba(99,110,123,0.1)))',
        }}
      >
        {creditsLoading ? (
          <Spinner size={18} className="animate-spin" />
        ) : (
          <Box className="flex items-center gap-1">
            <DotFillIcon
              fill={
                status === 'Running' ||
                status === 'Initializing' ||
                status === 'Deploying' ||
                workspaceStatus === 'Running' ||
                workspaceStatus === 'Initializing' ||
                workspaceStatus === 'Deploying'
                  ? '#14C078'
                  : '#bdbdbd'
              }
            />
            <span className="flex items-center gap-1">
              Credits:
              <p className="flex items-center">
                <CurrencyDollarSimple /> {credits}
              </p>
            </span>
          </Box>
        )}
      </ActionMenu.Button>
      <ActionMenu.Overlay
        width="small"
        height="fit-content"
        align="end"
        sx={{
          borderRadius: '0.25rem',
        }}
      >
        <Box className="flex flex-col gap-3">
          <p className="text-sm border-b border-[#636C76] px-5 py-2">Active Resources</p>
          <Box className="flex flex-col gap-3.5 px-5 pb-4">{children}</Box>
        </Box>
      </ActionMenu.Overlay>
    </ActionMenu>
  );
};

import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';

import { Notifications } from '@/components/Notifications/Notifications';
import { AuthProvider } from '@/lib/auth';
import { queryClient } from '@/lib/react-query';
import { Spinner } from '@primer/react';
import {
  NoInternetConnectionPage,
  NotAuthorizedPage,
  SomethingWentWrongPage,
} from '@/features/misc';
import { ENVIRONMENT } from '@/config';

const ErrorFallback = ({ error }: { error: any }) => {
  if (!navigator.onLine) {
    return <NoInternetConnectionPage />;
  } else if (error.message === 'Request failed with status code 403') {
    return <NotAuthorizedPage />;
  } else if (error.message === 'Request failed with status code 402') {
    window.location.href = '/pricing';
  } else {
    return <SomethingWentWrongPage />;
  }
};

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <React.Suspense
      fallback={
        <div className="flex items-center justify-center w-screen h-screen">
          <Spinner size="large" />
        </div>
      }
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            {ENVIRONMENT === 'development' && <ReactQueryDevtools />}
            <Notifications />
            <AuthProvider>
              <Router>{children}</Router>
            </AuthProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};

import React, { useRef } from 'react';
import { Box, Button } from '@primer/react';
import { Dialog } from '@primer/react/experimental';

interface DialogProps {
  isOpen: boolean;
  onDismiss: () => void;
  children: React.ReactNode;
  sx?: object;
  buttonText?: any;
  showButtons?: boolean;
  disablePrimaryButton?: boolean;
  customHeader?: () => React.ReactElement;
  primaryButtonOnClick?: () => void;
  warningModal?: boolean;
  cancelButtonOnClick?: () => void;
  disableCancelButton?: boolean;
  backButtonOnClick?: () => void;
  showBackButton?: boolean;
  showOnlyPrimaryButton?: boolean;
  disableBackButton?: boolean;
  isPrimaryButtonLoading?: boolean;
}

export const Modal: React.FC<DialogProps> = ({
  isOpen,
  onDismiss,
  children,
  sx = {},
  buttonText = '',
  showButtons = true,
  customHeader,
  primaryButtonOnClick,
  disablePrimaryButton = false,
  warningModal = false,
  cancelButtonOnClick = () => {},
  disableCancelButton = false,
  disableBackButton = false,
  backButtonOnClick = () => {},
  showBackButton = false,
  showOnlyPrimaryButton = false,
  isPrimaryButtonLoading = false,
}) => {
  const returnFocusRef = useRef<HTMLButtonElement>(null);

  const CustomFooter = () => {
    return (
      <Box className="flex border-t border-[#636C76] justify-end gap-2 p-3">
        {showBackButton ? (
          <Button
            variant="invisible"
            disabled={disableBackButton}
            onClick={() => {
              backButtonOnClick();
            }}
            sx={{
              color: '#fff',
            }}
          >
            Back
          </Button>
        ) : showOnlyPrimaryButton ? null : (
          <Button
            variant="invisible"
            disabled={disableCancelButton}
            onClick={() => {
              onDismiss();
              cancelButtonOnClick();
            }}
            sx={{
              color: '#fff',
            }}
          >
            Cancel
          </Button>
        )}
        {warningModal ? (
          <button
            onClick={primaryButtonOnClick}
            disabled={disablePrimaryButton}
            className={`text-sm rounded-3xl px-3 text-white bg-[#E83F3F] disabled:bg-[#D3D3D3] disabled:text-[#A9A9A9] disabled:cursor-not-allowed`}
          >
            {buttonText}
          </button>
        ) : (
          <Button
            onClick={primaryButtonOnClick}
            disabled={disablePrimaryButton}
            loading={isPrimaryButtonLoading}
            sx={{
              fontSize: '14px',
              backgroundColor: '#fff',
              borderRadius: '24px',
              color: '#16191D',
              '&:hover': {
                color: '#fff',
              },
              '&:disabled': {
                backgroundColor: isPrimaryButtonLoading ? 'transparent' : '#D3D3D3',
                border: '1px solid #A9A9A9',
                color: '#A9A9A9',
                '&:hover': {
                  color: '#A9A9A9',
                  border: '1px solid #A9A9A9',
                },
              },
            }}
          >
            {buttonText}
          </Button>
        )}
      </Box>
    );
  };

  return (
    <>
      {isOpen && (
        <Dialog
          sx={sx}
          renderHeader={customHeader}
          renderFooter={showButtons ? CustomFooter : undefined}
          returnFocusRef={returnFocusRef}
          onClose={onDismiss}
        >
          {children}
        </Dialog>
      )}
    </>
  );
};

import React, { useEffect } from 'react';
import { Box, Tooltip } from '@primer/react';
import {
  ChartLineUp,
  Gear,
  Monitor,
  PencilSimple,
  PlayCircle,
  RocketLaunch,
  UserCircle,
} from 'phosphor-react';
import { UserNavigation } from '@/components/Layout';
import { useLocation, useNavigate } from 'react-router-dom';

interface LeftSidebarProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  userDetails: any;
}

type SidebarItemProps = {
  icon: JSX.Element;
  onClick: () => void;
  isActive: boolean;
  tooltip: string;
};

const SidebarItem = ({ icon, onClick, isActive, tooltip }: SidebarItemProps) => (
  <Tooltip aria-label={tooltip} direction="e">
    <div
      style={isActive ? { borderLeft: '2.5px solid white' } : {}}
      className="flex justify-center h-7 items-center"
    >
      <div onClick={onClick} className="flex justify-center cursor-pointer">
        {icon}
      </div>
    </div>
  </Tooltip>
);

const sidebarItems = [
  // {
  //   tabName: 'dashboard',
  //   icon: <ChartLineUp size={22} />,
  //   activeIcon: <ChartLineUp size={22} weight="fill" />,
  //   tooltip: 'Dashboard',
  // },
  {
    tabName: 'editor',
    icon: <PencilSimple size={22} />,
    activeIcon: <PencilSimple weight="fill" size={22} />,
    tooltip: 'Editor',
  },
  // {
  //   tabName: 'pods',
  //   icon: <Monitor size={22} />,
  //   activeIcon: <Monitor size={22} weight="fill" />,
  //   tooltip: 'Pods',
  // },
  {
    tabName: 'deployments',
    icon: <RocketLaunch size={22} />,
    activeIcon: <RocketLaunch size={22} weight="fill" />,
    tooltip: 'Deployments',
  },
  {
    tabName: 'runs',
    icon: <PlayCircle size={22} />,
    activeIcon: <PlayCircle size={22} weight="fill" />,
    tooltip: 'Runs',
  },
  {
    tabName: 'settings',
    icon: <Gear size={22} />,
    activeIcon: <Gear size={22} weight="fill" />,
    tooltip: 'Settings',
  },
];

const LeftSidebar: React.FC<LeftSidebarProps> = ({ activeTab, setActiveTab, userDetails }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleTabChange = (tabName: string) => {
    navigate(`?${tabName}`);
    setActiveTab(tabName);
  };

  useEffect(() => {
    const currentTab = location.search.slice(1); // Get the part after "?"
    if (currentTab && sidebarItems.some((item) => item.tabName === currentTab)) {
      setActiveTab(currentTab);
    }
  }, [location.search, setActiveTab]);
  return (
    <Box className="flex flex-col h-full gap-4 bg-[#16191D]">
      <Box
        className="flex flex-col justify-between h-full pb-3 pt-3"
        sx={{ borderRight: '1px solid #343B45', width: '50px' }}
      >
        <Box className="flex flex-col gap-5">
          {sidebarItems.map((item, index) => (
            <SidebarItem
              key={index}
              icon={activeTab === item.tabName ? item.activeIcon : item.icon}
              onClick={() => handleTabChange(item.tabName)}
              isActive={activeTab === item.tabName}
              tooltip={item.tooltip}
            />
          ))}
        </Box>
        <Box className={`pl-1.5 ${activeTab === 'profile' ? 'border-l-2' : ''}`}>
          <UserNavigation userDetails={userDetails} />
        </Box>
      </Box>
    </Box>
  );
};

export default LeftSidebar;

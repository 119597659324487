import React, { ReactNode, useEffect, useState } from 'react';
import LogoWithFullName from '@/assets/logo-with-fullname.svg';
import { useGetQueueData } from '../api/getGradioQueue';
import { Box } from '@primer/react';
import { DotFillIcon } from '@primer/octicons-react';
import { Spinner } from 'phosphor-react';
import { useParams } from 'react-router-dom';
import { useSSE } from '@/lib/useSSE';

interface GradioLayoutProps {
  children: ReactNode;
}

export const GradioLayout: React.FC<GradioLayoutProps> = ({ children }) => {
  const [isGenerationStatusRunning, setIsGenerationStatusRunning] = useState(false);

  const { latestDeploymentId, sessionId } = useParams<{
    latestDeploymentId: string;
    sessionId: string;
  }>();

  const prm = useParams<{ latestDeploymentId: string }>();
  console.log('Gradio Params', prm);

  const apiDocsUrl = window.location.search.substring(1);

  const { data: generationData } = useSSE<any>(
    `/deployment/${latestDeploymentId}/running_status/stream/public?session_id=${sessionId}`,
    {},
    !!latestDeploymentId && !!sessionId,
    false,
    true
  );

  const { data, isLoading } = useGetQueueData(`https://${apiDocsUrl}`, {
    enabled: isGenerationStatusRunning,
  });

  useEffect(() => {
    if (generationData?.generation_status === 'Started' && generationData?.type === 'deployment') {
      setIsGenerationStatusRunning(true);
    } else {
      setIsGenerationStatusRunning(false);
    }
  }, [generationData]);

  const queue_pending = data?.data?.queue_pending?.length || 0;
  const queue_running = data?.data?.queue_running?.length || 0;

  const handleLogoClick = () => {
    window.location.href = 'https://hub.flowscale.ai';
  };

  return (
    <Box className="flex flex-col h-screen overflow-hidden bg-[#16191D]">
      <div className="relative z-[1] px-[120px] flex-shrink-0 flex h-16 bg-[#16191D] border-b border-[#343B45]">
        <div className="flex items-center">
          <img
            src={LogoWithFullName}
            onClick={handleLogoClick}
            className="h-6 cursor-pointer"
            alt="logo"
          />
        </div>

        <div className="flex-1 flex justify-end">
          <div className="flex gap-4 items-center">
            <div className="flex">
              <Box
                className="rounded-xl text-[#adbac7] gap-3 flex justify-center items-center px-3 py-1"
                sx={{
                  backgroundColor:
                    'var(--bgColor-neutral-muted, var(--color-neutral-subtle, rgba(99,110,123,0.1)))',
                }}
              >
                <DotFillIcon
                  fill={
                    generationData?.container_status === 'Running' &&
                    generationData?.type === 'deployment'
                      ? '#14C078'
                      : generationData?.container_status === 'Idle' &&
                        generationData?.type === 'deployment'
                      ? '#c3c601'
                      : (generationData?.container_status === 'Initializing' ||
                          generationData?.container_status === 'Deploying') &&
                        generationData?.type === 'deployment'
                      ? '#bdbdbd'
                      : '#de0202'
                  }
                />
                <p>GPU Status:</p>
                <p className="text-sm">{generationData?.container_status?.toUpperCase()}</p>
              </Box>
            </div>
            <div className="flex">
              <Box
                className="rounded-xl text-[#adbac7] gap-3 flex justify-center items-center px-3 py-1"
                sx={{
                  backgroundColor:
                    'var(--bgColor-neutral-muted, var(--color-neutral-subtle, rgba(99,110,123,0.1)))',
                }}
              >
                <DotFillIcon fill={queue_running === 0 ? '#f1f500' : '#14C078'} />
                <p>Running:</p>

                {isLoading ? (
                  <Spinner size={20} className="animate-spin" />
                ) : queue_running === 0 ? (
                  <p className="text-sm">No running jobs</p>
                ) : (
                  <ul>
                    <li>{queue_running}</li>
                  </ul>
                )}
              </Box>
            </div>
            <div>
              <Box
                className="rounded-xl text-[#adbac7] gap-3 flex justify-center items-center px-3 py-1"
                sx={{
                  backgroundColor:
                    'var(--bgColor-neutral-muted, var(--color-neutral-subtle, rgba(99,110,123,0.1)))',
                }}
              >
                <DotFillIcon fill={queue_pending === 0 ? '#f1f500' : '#14C078'} />
                <p>Pending:</p>
                {isLoading ? (
                  <Spinner size={20} className="animate-spin" />
                ) : queue_pending === 0 ? (
                  <p className="text-sm">No pending jobs</p>
                ) : (
                  <ul>
                    <li>{queue_pending}</li>
                  </ul>
                )}
              </Box>
            </div>
          </div>
        </div>
      </div>
      <main className="flex-1 relative overflow-y-auto focus:outline-none">{children}</main>
    </Box>
  );
};

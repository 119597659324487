import React, { ReactNode, useEffect } from 'react';
import LogoWithFullName from '@/assets/logo-with-fullname.svg';
import { Box } from '@primer/react';

interface WorkspaceLayoutProps {
  children: ReactNode;
}

export const WorkspaceLayout: React.FC<WorkspaceLayoutProps> = ({ children }) => {
  const pathname = window.location.pathname;
  const workspaceName = pathname.split('/')[2];

  // Dynamically set favicon and browser tab title
  useEffect(() => {
    const browserTabTitle = document.getElementById('tab-title') as HTMLTitleElement;

    if (browserTabTitle) {
      browserTabTitle.innerText = `${workspaceName} | Flowscale Hub`;
    }
  }, [workspaceName]);

  const handleLogoClick = () => {
    window.location.href = 'https://hub.flowscale.ai';
  };

  return (
    <Box className="flex flex-col h-screen overflow-hidden bg-[#16191D]">
      {/* <div className="relative z-[1] px-[120px] flex-shrink-0 flex h-16 bg-[#16191D] border-b border-[#343B45]">
        <div className="flex items-center">
          <img
            src={LogoWithFullName}
            onClick={handleLogoClick}
            className="h-6 cursor-pointer"
            alt="logo"
          />
        </div>
      </div> */}
      <main className="flex-1 relative overflow-y-auto focus:outline-none">{children}</main>
    </Box>
  );
};

import * as React from 'react';
import { NavLink, useNavigate, Link, useLocation, useParams } from 'react-router-dom';
import LogoWithFullName from '@/assets/logo-with-fullname.svg';
import {
  GithubLogo,
  MagnifyingGlass,
  Lock,
  GitFork,
  XCircle,
  Spinner,
  CurrencyDollarSimple,
  CaretDown,
  RocketLaunch,
} from 'phosphor-react';
import logo from '@/assets/logo.svg';
import {
  ActionList,
  ActionMenu,
  Avatar,
  Box,
  Button,
  FormControl,
  Label,
  TextInput,
  Token,
} from '@primer/react';
import BreadcrumbsComponent from '../Elements/Breadcrumb/Breadcrumb';
import { useAppContext } from '@/context/AppContext';
import { useGetUserDetails } from '@/features/auth';
import {
  useForkRepository,
  useGetRepoDetailsById,
  useGetRepositoryById,
} from '@/features/repositories';
import { useAddUserRepositories } from '@/features/repositories/api/addUserRepositores';
// import { useAuthorization, ROLES } from '@/lib/authorization';
import { DotFillIcon } from '@primer/octicons-react';
import { UserNavigation } from './UserNavigation';
import { useGetCredits } from '@/features/pricing';
import { Head } from '../Head';
import { DropdownMorphed } from '../Elements/Dropdown';
import { useSSE } from '@/lib/useSSE';
import CustomButton from '../Elements/Button/CustomButton';
import LeftSidebar from './LeftSidebar';
import { useDeploymentDependenciesStore } from '@/features/deployments';
import { useGetFlowscaleUser } from '@/features/users';
import { TeamsDropdown } from '@/features/teams';
import { useGetTeam } from '@/features/teams/api/getTeam';
import { generateIdenticon } from '@/utils/randomLogos';
import { useGetVolumeId } from '@/features/app/api/getVolumeId';

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  // const [repoName, setRepoName] = React.useState('');
  // const [repoUrl, setRepoUrl] = React.useState('');
  const [isPrivate, setIsPrivate] = React.useState(false);
  const [isForked, setIsForked] = React.useState(false);
  // const [latestWorkspaceDeploymentId, setLatestWorkspaceDeploymentId] = React.useState('');
  const [isWorkspaceContainerStatusStopped, setIsWorkspaceContainerStatusStopped] =
    React.useState(false);
  const [isDeploymentStatusStopped, setIsDeploymentStatusStopped] = React.useState(false);

  const { data: userCredits, isLoading: creditsLoading } = useGetCredits();
  const { data: userDetails } = useGetUserDetails();
  const { data: volume }: { data: any } = useGetVolumeId();
  const {
    isLoggedUserSameAsRepoUser,
    setRepoOwnerUserName,
    repoOwnerUserName,
    isDeploymentStopped,
    setLoggedInUsername,
    setRepoName,
    repo_name,
    loggedInUsername,
    setSearchRepositories,
    searchRepositories,
    isDeploymentStarted,
    setRepoUrl,
    repoUrl,
    setLatestWorkspaceStatus,
    setLatestDeploymentStatus,
    isWorkspaceStarted,
    isWorkspaceStopped,
    setRepoBranchName,
    setLatestDeploymentId,
    latestDeploymentId,
    setIsDeploymentCheckModalOpen,
    activeTabModule,
    setActiveTabModule,
    setDefaultWorkspaceId,
    setLatestWorkspaceDeploymentId,
    setIsLoadingRepoDeploymentStatus,
    latest_workspace_deployment_id,
    setUserVolumeId,
    setIsUserTeamOwner,
  } = useAppContext();
  // const { mutate: addUserRepository } = useAddUserRepositories();
  const { data: team, isLoading: isTeamLoading } = useGetTeam();

  const isUserTeamOwner =
    team?.data?.members?.find((member: any) => member?.role === 'owner')?.username ===
    userDetails?.login;

  React.useEffect(() => {
    setIsUserTeamOwner(isUserTeamOwner);
  }, [isUserTeamOwner]);

  React.useEffect(() => {
    setLoggedInUsername(userDetails?.login);
  }, [userDetails]);

  React.useEffect(() => {
    if (volume) {
      setUserVolumeId(volume?.data[0]?.volume_id);
    }
  }, [volume]);

  const navigate = useNavigate();
  const location = useLocation();
  const showLogoWithFullName =
    location.pathname.includes('/app/repositories') &&
    !location.pathname.includes('/app/repositories/');

  const hideOnProfilePage = location.pathname.includes('/app/profile');

  const { projectId } = useParams<{ projectId: any }>();
  const { data: repoDetails } = useGetRepoDetailsById(projectId || '');
  const { getIsDeploying } = useDeploymentDependenciesStore();

  const {
    data: repositoryDeploymentStatus,
    isLoading: isLoadingRepoDeploymentStatus,
    refetch: refetchRepoDeploymentStatus,
  } = useGetRepositoryById(projectId, {
    enabled: !!projectId,
  });

  const { data: runningStatus } = useSSE<any>(
    latestDeploymentId ? `/deployment/${latestDeploymentId}/running_status/stream` : '',
    {},
    !!latestDeploymentId,
    isDeploymentStatusStopped // Close the SSE connection if the deployment is stopped
  );

  React.useEffect(() => {
    if (isDeploymentStopped === true) {
      if (runningStatus?.container_status === 'Stopped') {
        setIsDeploymentStatusStopped(true);
      } else {
        setIsDeploymentStatusStopped(false);
      }
    }
  }, [runningStatus?.container_status, isDeploymentStopped]);

  const { data: runningWorkspaceStatus } = useSSE<any>(
    latest_workspace_deployment_id
      ? `/deployment/${latest_workspace_deployment_id}/running_status/stream`
      : '',
    {},
    true,
    isWorkspaceContainerStatusStopped // Close the SSE connection if the workspace is stopped
  );

  React.useEffect(() => {
    if (isWorkspaceStopped === true) {
      if (runningWorkspaceStatus?.container_status === 'Stopped') {
        setIsWorkspaceContainerStatusStopped(true);
      } else {
        setIsWorkspaceContainerStatusStopped(false);
      }
    }
  }, [runningWorkspaceStatus?.container_status, isWorkspaceStopped]);

  React.useEffect(() => {
    setIsLoadingRepoDeploymentStatus(isLoadingRepoDeploymentStatus);
  }, [isLoadingRepoDeploymentStatus]);

  React.useEffect(() => {
    setLatestDeploymentId(repositoryDeploymentStatus?.latest_deployment_id);
  }, [repositoryDeploymentStatus?.latest_deployment_id]);

  React.useEffect(() => {
    setLatestWorkspaceDeploymentId(repositoryDeploymentStatus?.latest_workspace_deployment_id);
  }, [repositoryDeploymentStatus?.latest_workspace_deployment_id]);

  React.useEffect(() => {
    if (isDeploymentStopped || isDeploymentStarted || isWorkspaceStarted || isWorkspaceStopped) {
      refetchRepoDeploymentStatus();
    }
  }, [isDeploymentStopped, isDeploymentStarted, isWorkspaceStarted, isWorkspaceStopped]);

  React.useEffect(() => {
    if (projectId && repoDetails) {
      setRepoName(repoDetails?.name);
      setRepoOwnerUserName(repoDetails?.owner?.login);
      setRepoUrl(repoDetails?.html_url);
      setIsPrivate(repoDetails?.private);
      setIsForked(repoDetails?.fork);
      setRepoBranchName(repoDetails?.default_branch);
      setDefaultWorkspaceId(
        repositoryDeploymentStatus?.default_workspace_id
          ? repositoryDeploymentStatus?.default_workspace_id
          : repositoryDeploymentStatus?.default_workspace_ids[0]
      );
    }
  }, [repoDetails, repositoryDeploymentStatus]);

  // const forkRepositoryMutation = useForkRepository({
  //   onSuccess: (data: any) => {
  //     addUserRepository({ repo_id: data?.id, repo_name });
  //     navigate('/app/repositories');
  //   },
  // });

  // const handleForkRepository = async () => {
  //   try {
  //     await forkRepositoryMutation.mutateAsync({
  //       owner: repoOwnerUserName,
  //       repo_name,
  //       default_branch_only: false,
  //     });
  //   } catch (error) {
  //     // console.error('Error forking repository:', error);
  //   }
  // };

  const menuItems = [
    { name: 'Back to Projects', url: '/app/repositories' },
    { name: 'Help', url: 'https://docs.flowscale.ai' },
  ];

  const handleDeployButtonClick = () => {
    setIsDeploymentCheckModalOpen(true);
  };

  return (
    <Box className="h-screen flex flex-col overflow-hidden bg-[#16191D]">
      <Head title={repoDetails?.name} />
      <div
        className={`${
          showLogoWithFullName ? 'px-[120px]' : 'px-2'
        } relative py-2 flex justify-between w-full border-b border-[#343B45]`}
      >
        <Box className="flex items-center w-full justify-center gap-2">
          {showLogoWithFullName ? (
            <Box className="flex items-center justify-between w-full">
              <img
                src={LogoWithFullName}
                onClick={() => navigate('/app/repositories')}
                className="h-6 cursor-pointer"
                alt="logo"
              />
              <Box className="flex items-center gap-3">
                {/* <FormControl>
                  <FormControl.Label visuallyHidden>Default label</FormControl.Label>
                  <TextInput
                    leadingVisual={MagnifyingGlass}
                    placeholder="Search projects"
                    sx={{ width: '320px', backgroundColor: '#16191D' }}
                    size="medium"
                    value={searchRepositories}
                    onChange={(e) => {
                      setSearchRepositories(e.target.value);
                      navigate(`/app/repositories?projects`);
                    }}
                    trailingAction={
                      searchRepositories.length > 0 ? (
                        <TextInput.Action
                          onClick={(e) => {
                            setSearchRepositories('');
                            e.preventDefault();
                          }}
                          icon={XCircle}
                          aria-label="Clear"
                          sx={{
                            color: 'fg.subtle',
                          }}
                        />
                      ) : undefined
                    }
                  />
                </FormControl> */}
                <TeamsDropdown />
                <Box
                  sx={{
                    border: `1px solid #464b51`,
                    borderRadius: '6px',
                    fontSize: '12px',
                    padding: '6px 14px',
                    backgroundColor:
                      'var(--bgColor-neutral-muted, var(--color-neutral-subtle, rgba(99,110,123,0.1)))',
                  }}
                >
                  {creditsLoading ? (
                    <Spinner size={18} className="animate-spin" />
                  ) : (
                    <span className="flex items-center gap-1">
                      Credits:
                      <p className="flex items-center">
                        <CurrencyDollarSimple /> {userCredits?.credits?.toFixed(2)}
                      </p>
                    </span>
                  )}
                </Box>

                <UserNavigation
                  userDetails={userDetails}
                  menuClassName="top-8 left-0 z-[40] bg-[#272b31]"
                />
              </Box>
            </Box>
          ) : (
            <Box className="flex items-center justify-between w-full">
              <ActionMenu>
                <ActionMenu.Button
                  size="small"
                  trailingAction={CaretDown}
                  sx={{ backgroundColor: '#22262C', height: '35px' }}
                >
                  <img src={logo} alt="logo" />
                </ActionMenu.Button>

                <ActionMenu.Overlay>
                  <ActionList
                    sx={{
                      padding: '12px 8px',
                    }}
                  >
                    {menuItems.map((item, index) => (
                      <Box
                        key={index}
                        onClick={() => {
                          navigate(item.url);
                        }}
                        className="flex rounded-md items-center gap-2 py-2 px-3 hover:bg-gray-700 hover:text-white cursor-pointer text-xs"
                      >
                        {item.name}
                      </Box>
                    ))}
                  </ActionList>
                </ActionMenu.Overlay>
              </ActionMenu>

              <Box
                className="flex justify-center items-center gap-3 absolute"
                sx={{
                  left: '50%',
                  transform: 'translateX(-50%)',
                }}
              >
                <ol className="flex items-center">
                  <li>
                    <Link to="/app/repositories">
                      {isTeamLoading ? (
                        <Spinner size={18} className="animate-spin" />
                      ) : (
                        <Box className="flex items-center gap-1">
                          <Avatar
                            className="mr-1 border border-gray-700"
                            size={26}
                            src={
                              team?.data?.logo
                                ? team?.data?.logo
                                : generateIdenticon(team?.data?.name)
                            }
                          />
                          <span className="hover:border-b">{team?.data?.name}</span>
                        </Box>
                      )}
                    </Link>
                  </li>
                  <li className="mx-2">/</li>
                  <li>
                    <p aria-current="page">{hideOnProfilePage ? 'profile' : repo_name}</p>
                  </li>
                </ol>
                {isPrivate && (
                  <div
                    style={{
                      borderRadius: '24px',
                      padding: '6px 12px',
                      color: '#D7D7DB',
                      backgroundColor: '#343B45',
                      fontSize: '12px',
                    }}
                  >
                    <Lock />
                  </div>
                )}
                {isForked && (
                  <Token
                    leadingVisual={GitFork}
                    sx={{
                      borderRadius: '24px',
                      padding: '12px 14px',
                      color: '#D7D7DB',
                      backgroundColor: '#343B45',
                      fontSize: '12px',
                    }}
                    text={'Forked'}
                  />
                )}
                {repoOwnerUserName && (
                  <Button
                    leadingVisual={GithubLogo}
                    sx={{
                      border: '1px solid #636C76',
                      borderRadius: '24px',
                      color: '#D7D7DB',
                      fontSize: '12px',
                      backgroundColor: 'transparent',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(repoUrl, '_blank');
                    }}
                  >
                    View on Github
                  </Button>
                )}
              </Box>

              {!hideOnProfilePage && (
                <Box className="flex items-center gap-3">
                  <DropdownMorphed
                    status={runningStatus?.container_status}
                    workspaceStatus={runningWorkspaceStatus?.container_status}
                    credits={userCredits?.credits?.toFixed(2)}
                    creditsLoading={creditsLoading}
                  >
                    <Box className="text-xs gap-2 flex justify-between w-full">
                      <p className="font-bold">Deployment:</p>
                      <Box className="gap-1 flex">
                        <DotFillIcon
                          fill={
                            runningStatus?.container_status === 'Running' &&
                            runningStatus?.type === 'deployment'
                              ? '#14C078'
                              : runningStatus?.container_status === 'Idle' &&
                                runningStatus?.type === 'deployment'
                              ? '#c3c601'
                              : (runningStatus?.container_status === 'Initializing' ||
                                  runningStatus?.container_status === 'Deploying') &&
                                runningStatus?.type === 'deployment'
                              ? '#bdbdbd'
                              : '#de0202'
                          }
                        />
                        {isLoadingRepoDeploymentStatus ? (
                          <Spinner size={18} className="animate-spin" />
                        ) : runningStatus?.container_status === 'Running' &&
                          runningStatus?.type === 'deployment' ? (
                          <p>LIVE</p>
                        ) : !runningStatus?.container_status ? (
                          <p>STOPPED</p>
                        ) : runningStatus?.type === 'deployment' ? (
                          <p className="uppercase">{runningStatus?.container_status}</p>
                        ) : (
                          <p>STOPPED</p>
                        )}
                      </Box>
                    </Box>
                    <Box className="text-xs gap-2 flex justify-between w-full">
                      <p className="font-bold">Workspace:</p>
                      <Box className="gap-1 flex">
                        <DotFillIcon
                          fill={
                            runningWorkspaceStatus?.container_status === 'Running' &&
                            runningWorkspaceStatus?.type === 'workspace'
                              ? '#14C078'
                              : runningWorkspaceStatus?.container_status === 'Idle' &&
                                runningWorkspaceStatus?.type === 'workspace'
                              ? '#c3c601'
                              : (runningWorkspaceStatus?.container_status === 'Initializing' ||
                                  runningWorkspaceStatus?.container_status === 'Deploying') &&
                                runningWorkspaceStatus?.type === 'workspace'
                              ? '#bdbdbd'
                              : '#de0202'
                          }
                        />
                        {isLoadingRepoDeploymentStatus ? (
                          <Spinner size={18} className="animate-spin" />
                        ) : runningWorkspaceStatus?.container_status === 'Running' &&
                          runningWorkspaceStatus?.type === 'workspace' ? (
                          <p>LIVE</p>
                        ) : !runningWorkspaceStatus?.container_status ? (
                          <p>STOPPED</p>
                        ) : runningWorkspaceStatus?.type === 'workspace' ? (
                          <p className="uppercase">{runningWorkspaceStatus?.container_status}</p>
                        ) : (
                          <p>STOPPED</p>
                        )}
                      </Box>
                    </Box>
                  </DropdownMorphed>

                  {activeTabModule === 'editor' ? (
                    <CustomButton
                      leadingVisual={RocketLaunch}
                      style={{
                        backgroundColor: '#fff',
                        color: '#16191D',
                        padding: '4px 11px',
                        fontSize: '14px',
                      }}
                      hoverStyle={{
                        color: '#C4C4CA',
                        border: '1px solid #636C76',
                        backgroundColor: '#16191D',
                        fontSize: '14px',
                      }}
                      onClick={handleDeployButtonClick}
                      disabled={getIsDeploying(projectId)}
                    >
                      {getIsDeploying(projectId) ? 'Deploying' : 'Deploy'}
                    </CustomButton>
                  ) : null}
                </Box>
              )}
            </Box>
          )}
        </Box>

        {/* {!isLoggedUserSameAsRepoUser && !showLogoWithFullName && !hideOnProfilePage && (
                <Button
                  leadingVisual={GitFork}
                  sx={{
                    border: '1px solid #636C76',
                    padding: '8px, 16px, 8px, 16px',
                    borderRadius: '24px',
                    color: '#D7D7DB',
                    fontSize: '14px',
                    backgroundColor: 'transparent',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleForkRepository();
                  }}
                >
                  Fork this repository
                </Button>
              )} */}
      </div>
      <Box className="flex h-full overflow-hidden">
        {!showLogoWithFullName && !hideOnProfilePage && (
          <LeftSidebar
            activeTab={activeTabModule}
            setActiveTab={setActiveTabModule}
            userDetails={userDetails}
          />
        )}

        <main className="flex-1 relative flex-grow h-full focus:outline-none">{children}</main>
      </Box>
    </Box>
  );
};

import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface AppContextType {
  selectedRepoName: string | null;
  setSelectedRepoName: React.Dispatch<React.SetStateAction<string | null>>;
  repoOwnerUserName: string;
  setRepoOwnerUserName: React.Dispatch<React.SetStateAction<string>>;
  loggedInUsername: string;
  setLoggedInUsername: React.Dispatch<React.SetStateAction<string>>;
  isLoggedUserSameAsRepoUser: boolean;
  repoUrl: string;
  setRepoUrl: React.Dispatch<React.SetStateAction<string>>;
  setRepoName: React.Dispatch<React.SetStateAction<string>>;
  repo_name: string;
  isRepoDetailLoading: boolean;
  setIsRepoDetailLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchRepositories: React.Dispatch<React.SetStateAction<string>>;
  searchRepositories: string;
  setIsDeploymentStopped: React.Dispatch<React.SetStateAction<boolean>>;
  isDeploymentStopped: boolean;
  setIsDeploymentStarted: React.Dispatch<React.SetStateAction<boolean>>;
  isDeploymentStarted: boolean;
  setIsWorkspaceStarted: React.Dispatch<React.SetStateAction<boolean>>;
  isWorkspaceStarted: boolean;
  setIsWorkspaceStopped: React.Dispatch<React.SetStateAction<boolean>>;
  isWorkspaceStopped: boolean;
  setLatestDeploymentId: React.Dispatch<React.SetStateAction<any>>;
  latestDeploymentId: any;
  setModalsCredentialsAvailable: React.Dispatch<React.SetStateAction<boolean>>;
  isModalsCredentialsAvailable: boolean;
  setSavedDeploymentSettingsData: React.Dispatch<React.SetStateAction<any>>;
  savedDeploymentSettingsData: any;
  setLatestDeploymentStatus: React.Dispatch<React.SetStateAction<string>>;
  latest_deployment_status: string;
  setLatestWorkspaceStatus: React.Dispatch<React.SetStateAction<string>>;
  latest_workspace_status: string;
  repo_branch_name: string;
  setRepoBranchName: React.Dispatch<React.SetStateAction<string>>;
  isDeploymentSettingsApiLoading: boolean;
  setIsDeploymentSettingsApiLoading: React.Dispatch<React.SetStateAction<boolean>>;
  selectedWorkflowPath: string;
  setSelectedWorkflowPath: React.Dispatch<React.SetStateAction<string>>;
  setIsDeploymentCheckModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isDeploymentCheckModalOpen: boolean;
  activeTabModule: string;
  setActiveTabModule: React.Dispatch<React.SetStateAction<string>>;
  defaultWorkspaceId: string;
  setDefaultWorkspaceId: React.Dispatch<React.SetStateAction<string>>;
  latest_workspace_deployment_id: string;
  setLatestWorkspaceDeploymentId: React.Dispatch<React.SetStateAction<string>>;
  isLoadingRepoDeploymentStatus: boolean;
  setIsLoadingRepoDeploymentStatus: React.Dispatch<React.SetStateAction<boolean>>;
  user_volume_id: string;
  setUserVolumeId: React.Dispatch<React.SetStateAction<string>>;
  isUserTeamOwner: boolean;
  setIsUserTeamOwner: React.Dispatch<React.SetStateAction<boolean>>;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

interface AppContextProviderProps {
  children: ReactNode;
}

export const AppContextProvider: React.FC<AppContextProviderProps> = ({ children }) => {
  const [selectedRepoName, setSelectedRepoName] = useState<string | null>(null);
  const [repoOwnerUserName, setRepoOwnerUserName] = useState<string>('');
  const [loggedInUsername, setLoggedInUsername] = useState<string>('');
  const [isLoggedUserSameAsRepoUser, setIsLoggedUserSameAsRepoUser] = useState<boolean>(false);
  const [repoUrl, setRepoUrl] = useState<string>('');
  const [repo_name, setRepoName] = useState<string>('');
  const [isRepoDetailLoading, setIsRepoDetailLoading] = useState<boolean>(false);
  const [searchRepositories, setSearchRepositories] = useState<string>('');
  const [isDeploymentStopped, setIsDeploymentStopped] = useState<boolean>(false);
  const [isDeploymentStarted, setIsDeploymentStarted] = useState<boolean>(false);
  const [isWorkspaceStarted, setIsWorkspaceStarted] = useState<boolean>(false);
  const [isWorkspaceStopped, setIsWorkspaceStopped] = useState<boolean>(false);
  const [latestDeploymentId, setLatestDeploymentId] = useState<any>(null);
  const [isModalsCredentialsAvailable, setModalsCredentialsAvailable] = useState<boolean>(false);
  const [savedDeploymentSettingsData, setSavedDeploymentSettingsData] = useState<any>(null);
  const [latest_deployment_status, setLatestDeploymentStatus] = useState<string>('');
  const [latest_workspace_status, setLatestWorkspaceStatus] = useState<string>('');
  const [repo_branch_name, setRepoBranchName] = useState<string>('');
  const [isDeploymentSettingsApiLoading, setIsDeploymentSettingsApiLoading] =
    useState<boolean>(false);
  const [selectedWorkflowPath, setSelectedWorkflowPath] = useState<string>('');
  const [isDeploymentCheckModalOpen, setIsDeploymentCheckModalOpen] = useState<boolean>(false);
  const [activeTabModule, setActiveTabModule] = useState<string>('editor');
  const [defaultWorkspaceId, setDefaultWorkspaceId] = useState<string>('');
  const [latest_workspace_deployment_id, setLatestWorkspaceDeploymentId] = useState<string>('');
  const [isLoadingRepoDeploymentStatus, setIsLoadingRepoDeploymentStatus] =
    useState<boolean>(false);
  const [user_volume_id, setUserVolumeId] = useState<string>('');
  const [isUserTeamOwner, setIsUserTeamOwner] = useState<boolean>(false);

  useEffect(() => {
    setIsLoggedUserSameAsRepoUser(true);
  }, [repoOwnerUserName, loggedInUsername]);

  // console.log(
  //   'repoOwnerUserName',
  //   repoOwnerUserName,
  //   'loggedInUsername',
  //   loggedInUsername,
  //   'isLoggedUserSameAsRepoUser',
  //   isLoggedUserSameAsRepoUser
  // );

  return (
    <AppContext.Provider
      value={{
        selectedRepoName,
        setSelectedRepoName,
        repoOwnerUserName,
        setRepoOwnerUserName,
        loggedInUsername,
        setLoggedInUsername,
        isLoggedUserSameAsRepoUser,
        setRepoUrl,
        repoUrl,
        setRepoName,
        repo_name,
        setIsRepoDetailLoading,
        isRepoDetailLoading,
        setSearchRepositories,
        searchRepositories,
        setIsDeploymentStopped,
        isDeploymentStopped,
        setIsDeploymentStarted,
        isDeploymentStarted,
        setLatestDeploymentId,
        latestDeploymentId,
        setModalsCredentialsAvailable,
        isModalsCredentialsAvailable,
        setSavedDeploymentSettingsData,
        savedDeploymentSettingsData,
        setLatestDeploymentStatus,
        latest_deployment_status,
        setLatestWorkspaceStatus,
        latest_workspace_status,
        setIsWorkspaceStarted,
        isWorkspaceStarted,
        setIsWorkspaceStopped,
        isWorkspaceStopped,
        setRepoBranchName,
        repo_branch_name,
        setIsDeploymentSettingsApiLoading,
        isDeploymentSettingsApiLoading,
        setSelectedWorkflowPath,
        selectedWorkflowPath,
        isDeploymentCheckModalOpen,
        setIsDeploymentCheckModalOpen,
        activeTabModule,
        setActiveTabModule,
        defaultWorkspaceId,
        setDefaultWorkspaceId,
        latest_workspace_deployment_id,
        setLatestWorkspaceDeploymentId,
        isLoadingRepoDeploymentStatus,
        setIsLoadingRepoDeploymentStatus,
        user_volume_id,
        setUserVolumeId,
        isUserTeamOwner,
        setIsUserTeamOwner,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = (): AppContextType => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};
